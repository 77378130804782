import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const About = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="w-full h-full p-4">
      <div
        className="flex items-center space-x-2 py-4 cursor-pointer"
        onClick={() => navigate('/')}>
        <svg
          id="Layer_1"
          version="1.1"
          viewBox="0 0 512 512"
          className="w-8 h-8 rtl:rotate-180">
          <polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 " />
        </svg>
        <span className="text-3xl font-bold">{t('common.back')}</span>
      </div>
      <h1 className="underline decoration-pastel-green decoration-4 font-bold text-2xl">
        {t('common.slogan')}
      </h1>
      <p className="my-4 text-black/70 max-w-4xl mx-auto">{t('about.text')}</p>
      <div className="mt-auto text-paragraph text-center">
        Some illustrations used in this app are provided by{' '}
        <a
          className="text-black underline"
          href="https://icons8.com/illustrations/author/zD2oqC8lLBBA">
          Icons 8
        </a>{' '}
        from{' '}
        <a
          className="text-black underline"
          href="https://icons8.com/illustrations">
          Ouch!
        </a>
      </div>
    </div>
  )
}

export default About
