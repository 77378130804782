import { useLocalStorage, useSessionStorage } from '@uidotdev/usehooks'
import React from 'react'

const Element = ({
  title,
  titleTranslations,
  icon,
  color,
  parentTitle,
  isFavorite,
  onItemClick,
}: {
  title: string
  titleTranslations: {
    [key: string]: string
  }
  icon: string
  color: string
  parentTitle?: string
  isFavorite: boolean
  onItemClick: (category: string) => void
}) => {
  const [imagesCache] = useSessionStorage<any>('images-cache', {})
  const [lang] = useLocalStorage('lang', 'en')

  return (
    <div
      className={`rounded-md aspect-square flex justify-center items-center flex-col backdrop-blur-sm cursor-pointer`}
      onClick={() => onItemClick(title)}
      style={{
        backgroundColor: `${color}55`,
      }}>
      <img
        src={imagesCache[`icons/${parentTitle || title}/${icon}`]||`/images/icons/${parentTitle || title}/${icon}`}
        className="max-w-[90%] h-28 aspect-auto"
      />
      <span className="font-semibold text-xl mt-1 text-center">
        {lang === 'en' ? title : titleTranslations[lang]}
      </span>
      {isFavorite && (
        <span className="text-yellow-400 shadow-sm text-4xl absolute top-1 right-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24">
            <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
          </svg>
        </span>
      )}
    </div>
  )
}

export default Element
