import React from 'react'
import Element from './Element'

const CategoriesGrid = ({
  items,
  favorites,
  onItemClick,
}: {
  items: {
    title: string
    titleTranslations: {
      [key: string]: string
    }
    icon: string
    color: string
    parentTitle?: string
  }[]
  favorites: {
    title: string
    titleTranslations: {
      [key: string]: string
    }
    parentTitle?: string
  }[]
  onItemClick: (category: string) => void
}) => {
  return (
    <>
      <div className="grid grid-cols-2 w-full gap-4 p-4 md:grid-cols-4 lg:grid-cols-6">
        {items.map((item) => (
          <Element
            key={item.title}
            {...item}
            onItemClick={onItemClick}
            isFavorite={
              !!favorites.find(
                (f) =>
                  f.title === item.title && f.parentTitle === item.parentTitle,
              )
            }
          />
        ))}
      </div>
    </>
  )
}

export default CategoriesGrid
