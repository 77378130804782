import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { avatars } from '../../assets/avatars'

const Step3 = ({
  onContinue,
  userData,
}: {
  onContinue: (avatar: string) => void
  userData: { name: string }
}) => {
  const { t } = useTranslation()
  const [selectedAvatar, setSelectedAvatar] = useState<string>('')

  return (
    <div className="text-center h-full w-full flex items-center flex-col">
      <h1 className="text-3xl md:text-5xl text-heading font-extrabold">
        {t('onboarding.step3.title', {
          name: userData.name,
        })}
      </h1>
      <p className="font-normal text-paragraph my-4">
        {t('onboarding.step3.description')}
      </p>
      <div className="grid grid-cols-5 gap-2 p-4 sm:d-cols-7 md:grid-cols-8 lg:grid-cols-12 h-full w-full max-h-[60%]">
        {Object.entries(avatars).splice(0,25).map(([id, Svg]) => (
          <div
            key={id}
            className={`rounded-full flex-grow-0 cursor-pointer aspect-square p-1 ${
              selectedAvatar === id ? 'bg-pastel-green animate-pulse' : ''
            }`}
            onClick={() => {
              setSelectedAvatar(id)
            }}>
            <Svg />
          </div>
        ))}
      </div>
      <button
        className="bg-pastel-green text-white font-bold text-xl rounded-xl px-8 py-6 justify-self-end fixed bottom-10 disabled:opacity-50 disabled:bg-slate-600"
        disabled={selectedAvatar === ''}
        onClick={() => onContinue(selectedAvatar)}>
        {t('onboarding.step3.button')}
      </button>
    </div>
  )
}

export default Step3
