import React, { useMemo, useState } from 'react'
import CategoriesGrid from '../components/Categories/Grid'
import themes from '../data/themes.json'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PhrasesList from '../components/Modal/PhrasesList'
import { useLocalStorage } from '@uidotdev/usehooks'

const Category = () => {
  const { category } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [modalVisibleFor, setModalVisibleFor] = useState<string | null>(null)
  const [favorites] = useLocalStorage('my-favorites', {
    title: 'Favorites',
    titleTranslations: {
      fr: 'Favoris',
      ar: 'المفضلة',
    },
    icon: 'favorites.webp',
    color: '#FAEDCB',
    items: [],
  })
  const [lang] = useLocalStorage<'fr' | 'ar' | 'en'>('lang', 'en')

  const items = useMemo(
    () =>
      [favorites, ...themes]
        .find((theme) => theme.title === category)
        ?.items.map((item: any) => ({
          ...item,
          color: [favorites, ...themes].find(
            (theme) => theme.title === category,
          )?.color as string,
          parentTitle: item.parentTitle || category,
          parentTitleTranslations:
            item.parentTitleTranslations ||
            [favorites, ...themes].find((theme) => theme.title === category)
              ?.titleTranslations,
        })),
    [favorites, themes],
  )

  const categoryTranslation = useMemo(() => {
    if (lang === 'en') return category
    else {
      return [favorites, ...themes].find((theme) => theme.title === category)
        ?.titleTranslations[lang]
    }
  }, [])

  const handleClick = (category: string) => {
    setModalVisibleFor(category)
  }

  if (!items && category !== 'Favorites') {
    navigate('/')
    return null
  }

  return (
    <div className="w-full h-full">
      {items && items.length > 0 && modalVisibleFor !== null && (
        <PhrasesList
          onClose={() => setModalVisibleFor(null)}
          item={items.find((i) => i.title === modalVisibleFor) as any}
        />
      )}

      <div
        className="flex items-center space-x-2 p-4 cursor-pointer"
        onClick={() => navigate('/')}>
        <svg
          id="Layer_1"
          version="1.1"
          viewBox="0 0 512 512"
          className="w-8 h-8 rtl:rotate-180">
          <polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 " />
        </svg>
        <span className="text-3xl font-bold">{categoryTranslation}</span>
      </div>
      {!items?.length && (
        <div className="w-full h-full flex justify-center items-center px-4 py-8 font-md text-center">
          {t('common.no-favorites')}
        </div>
      )}
      {items && items.length > 0 && (
        <CategoriesGrid
          items={items}
          favorites={favorites.items}
          onItemClick={handleClick}
        />
      )}
    </div>
  )
}

export default Category
