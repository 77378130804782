import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { avatars } from '../../assets/avatars'
import { LANGUAGES } from '../../i18n/languages'
import { useLocalStorage } from '@uidotdev/usehooks'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const Navbar = ({ userData }: { userData: any }) => {
  const [lang, setLang] = useLocalStorage('lang', 'en')
  const { t } = useTranslation()
  const navigate = useNavigate()

  const Avatar = avatars[userData?.avatar as keyof typeof avatars]
  return (
    <nav className="w-full whitespace-nowrap h-20 fixed z-20 backdrop-blur-lg">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <span className="self-center text-2xl font-medium whitespace-nowrap max-w-[70%]  overflow-hidden text-ellipsis">
          {t('common.hello')}{' '}
          <span className="font-bold underline decoration-4 decoration-pastel-green">
            {userData.name}
          </span>
          !
        </span>
        <div className="flex items-center md:order-2 relative">
          <Menu>
            <Menu.Button className="w-14 h-14 text-3xl rounded-full bg-[#FF8080]/20 p-1">
              {
                // @ts-ignore
                LANGUAGES[lang]?.icon
              }
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="absolute top-full right-1/2 rtl:right-auto rtl:left-1/2 z-50 mt-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {Object.entries(LANGUAGES).map(([key, language]) => (
                    <Menu.Item>
                      {({ active }) => (
                        <span
                          onClick={() => setLang(key)}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'px-4 py-2 text-xl flex space-x-2 rtl:space-x-0',
                          )}>
                          <span className="rtl:ml-2">{language.icon} </span>
                          <span>{language.title}</span>
                        </span>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <Menu>
            <Menu.Button
              as="button"
              className="flex text-sm rounded-full md:mr-0 ml-2 rtl:ml-0 rtl:mr-2 focus:bg-pastel-green">
              <div className="w-14 h-14 rounded-full bg-pastel-green/50 p-1">
                <Avatar className="" />
              </div>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="absolute top-full right-0 rtl:left-0 rtl:right-auto z-50 mt-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    <span
                      onClick={() => navigate('/about')}
                      className={
                        'text-gray-700 px-4 py-2 text-xl flex space-x-2 rtl:space-x-0 items-center cursor-pointer'
                      }>
                      <span className="rtl:ml-2 flex items-center justify-center">
                        <svg
                          className="fill-pastel-green"
                          width="24"
                          height="24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill-rule="evenodd"
                          clip-rule="evenodd">
                          <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm.5 17h-1v-9h1v9zm-.5-12c.466 0 .845.378.845.845 0 .466-.379.844-.845.844-.466 0-.845-.378-.845-.844 0-.467.379-.845.845-.845z" />
                        </svg>
                      </span>
                      <span>{t('common.about')}</span>
                    </span>
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
