import { useSessionStorage } from '@uidotdev/usehooks'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Step1 = ({ onContinue }: { onContinue: () => void }) => {
  const [imagesCache] = useSessionStorage<any>('images-cache', {})
  const { t } = useTranslation()
  return (
    <div className="text-center h-full flex items-center flex-col">
      <img
        src={imagesCache['3d-hands-fun-and-wild-white-skin-hand-waving-hi.webp'] || '/images/3d-hands-fun-and-wild-white-skin-hand-waving-hi.webp'}
        alt="3d hand saying hi"
        className="mx-auto w-[10rem] min-h-[14rem] my-10"
      />
      <h1 className="text-3xl md:text-5xl text-heading font-extrabold">
        {t('onboarding.step1.title')}
      </h1>
      <p className="font-normal text-paragraph my-4">
        {t('onboarding.step1.description')}
      </p>
      <button
        className="bg-pastel-green text-white font-bold text-xl rounded-xl px-8 py-6 justify-self-end fixed bottom-10"
        onClick={onContinue}>
        {t('onboarding.step1.button')}
      </button>
    </div>
  )
}

export default Step1
