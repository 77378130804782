import React, { useMemo } from 'react'
import CategoriesGrid from '../components/Categories/Grid'
import themes from '../data/themes.json'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from '@uidotdev/usehooks'

const Home = () => {
  const navigate = useNavigate()
  const handleClick = (category: string) => {
    navigate(`/category/${category}`)
  }

  const [favorites] = useLocalStorage('my-favorites', {
    title: 'Favorites',
    titleTranslations: {
      fr: 'Favoris',
      ar: 'المفضلة',
    },
    icon: 'favorites.webp',
    color: '#FAEDCB',
    items: [],
  })

  return (
    <div className="w-full h-full">
      <CategoriesGrid
        items={[favorites, ...themes]}
        favorites={[]}
        onItemClick={handleClick}
      />
    </div>
  )
}

export default Home
