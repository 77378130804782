// json files
import en from './en.json'
import fr from './fr.json'
import ar from './ar.json'

export const LANGUAGES = {
  en: {
    translation: en,
    icon: '🇦🇺',
    title: 'English',
  },
  ar: {
    translation: ar,
    icon: '🇲🇦',
    title: 'العربية',
  },
  fr: {
    translation: fr,
    icon: '🇫🇷',
    title: 'Français',
  },
}
