import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { LANGUAGES } from './languages'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: LANGUAGES,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })

// on language change, update the html lang attribute
i18n.on('languageChanged', (lng) => {
  document.documentElement.lang = lng
  document.documentElement.dir = i18n.dir(lng)
})
