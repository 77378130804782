import React, { Component, useEffect } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { useSessionStorage } from '@uidotdev/usehooks'

// pages
import NavLayout from './NavLayout'
import Onboarding from './pages/Onboarding'
import Home from './pages/Home'
import Category from './pages/Category'
import About from './pages/About'

const importAll = (r: any) => {
  const images: any = {}
  r.keys().forEach((item: any) => {
    images[item.replace('./', '')] = r(item)
  })
  return images
}

const preloadImages = () => {
  const images = importAll(
    // @ts-ignore
    require.context('../public/images/', true, /\.(webp)$/),
  )
  Object.values(images).forEach((image: any) => {
    const img = new Image()
    img.src = typeof image === 'string' ? image : image.default
  })
  return images
}

const router = createBrowserRouter([
  {
    id: 'root',
    path: '/',
    Component: NavLayout,
    children: [
      {
        index: true,
        Component: Home,
      },
      {
        path: '/category/:category',
        Component: Category,
      },
      {
        path: '/about',
        Component: About,
      },
      {
        path: '/*',
        Component: Home,
      },
    ],
  },
  {
    path: '/onboarding',
    element: (
      <main className="w-screen h-screen overflow-hidden flex justify-center items-center text-xl font-medium font-inter">
        <Onboarding />
      </main>
    ),
  },
])

function App() {
  const [_, setImagesCache] = useSessionStorage('images-cache', {})
  useEffect(() => {
    const images = preloadImages()
    setImagesCache(images)
  }, [])
  return <RouterProvider router={router} />
}

export default App
