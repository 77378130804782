import { useLocalStorage, useSessionStorage } from '@uidotdev/usehooks'
import React, { useEffect, useMemo, useState } from 'react'

const PhrasesList = ({
  item,
  onClose,
}: {
  item: {
    title: string
    icon: string
    parentTitle: string
    parentTitleTranslations: {
      [key: string]: string
    }
    titleTranslations: {
      [key: string]: string
    }
    subItems: {
      phrase: string
      phraseTranslations: {
        [key: string]: string
      }
      emoji: string
    }[]
  }
  onClose: () => void
}) => {
  const [imagesCache] = useSessionStorage<any>('images-cache', {})
  const [isFavorite, setIsFavorite] = useState(false)
  const [lang] = useLocalStorage<'fr' | 'ar' | 'en'>('lang', 'en')
  const [userData] = useLocalStorage('user-data')
  const [favorites, setFavorites] = useLocalStorage('my-favorites', {
    title: 'Favorites',
    titleTranslations: {
      fr: 'Favoris',
      ar: 'المفضلة',
    },
    icon: 'favorites.webp',
    color: '#FAEDCB',
    items: [] as any,
  })

  useEffect(() => {
    if (
      item &&
      favorites.items.find(
        (i: any) =>
          i.title === item.title && i.parentTitle === item.parentTitle,
      )
    )
      setIsFavorite(true)
    else setIsFavorite(false)
  }, [favorites])

  if (!item) {
    onClose()
    return null
  }

  const toggleIsFavorite = () => {
    if (!isFavorite) {
      setFavorites({
        ...favorites,
        items: [...favorites.items, item],
      })
    } else {
      let newItems = favorites.items.filter((i: any) => i.title !== item.title)
      console.log(newItems)
      setFavorites({
        ...favorites,
        items: newItems,
      })
    }
  }

  const playPhrase = async (phrase: string) => {
    const voices = {
      en: {
        male: 'Matthew',
        female: 'Joanna',
      },
      fr: {
        male: 'Mathieu',
        female: 'Lea',
      },
      ar: {
        male: 'Ali',
        female: 'Inaya',
      },
    }

    let url = `https://talkify.net/api/speech/v1?key=77dfc763-2b3f-446d-bdc5-8d0e7e736a03&text=${phrase}&rate=0&format=mp3&voice=${
      // @ts-ignore
      voices[lang][userData.gender]
    }`
    let audio = new Audio(url)
    audio.play()
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full backdrop-blur-md bg-white/25 z-50 flex flex-col items-center justify-center">
      <div className="flex w-full px-16 max-w-md absolute top-20 items-center justify-between">
        <span
          className="flex justify-center items-center text-white text-3xl w-12 h-12 rounded-full bg-red-400 cursor-pointer"
          onClick={onClose}>
          &times;
        </span>
        <span
          onClick={() => toggleIsFavorite()}
          className={`flex justify-center items-center  text-3xl w-12 h-12 rounded-full bg-white-400 cursor-pointer
            
                    ${
                      isFavorite
                        ? 'bg-yellow-400 text-white border-0'
                        : 'text-gray-500 border-gray-500 border-2'
                    }
            `}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24">
            <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
          </svg>
        </span>
      </div>
      <img
        src={
          imagesCache[`icons/${item.parentTitle || item.title}/${item.icon}`] ||
          `/images/icons/${item.parentTitle || item.title}/${item.icon}`
        }
        className="w-1/2 max-w-xs"
      />
      <span className="font-semibold text-3xl mt-2">
        {lang === 'en' ? item.title : item.titleTranslations[lang]}
      </span>
      <span className="text-paragraph my-3">
        {lang === 'en' ? item.parentTitle : item.parentTitleTranslations[lang]}
      </span>
      <ul className="space-y-3 px-4">
        {item.subItems.map((subItem) => (
          <li
            className="flex items-start space-x-3 rtl:space-x-0 cursor-pointer group"
            onClick={() =>
              playPhrase(
                lang === 'en'
                  ? subItem.phrase
                  : subItem.phraseTranslations[lang],
              )
            }>
            <span className="rtl:ml-3">{subItem.emoji}</span>
            <span className="transition-opacity group-hover:opacity-50 duration-100">
              {lang === 'en'
                ? subItem.phrase
                : subItem.phraseTranslations[lang]}
            </span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default PhrasesList
