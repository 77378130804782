import { useSessionStorage } from '@uidotdev/usehooks'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Step2 = ({ onContinue }: { onContinue: (name: string) => void }) => {
  const [imagesCache] = useSessionStorage<any>('images-cache', {})
  const { t } = useTranslation()
  const [error, setError] = React.useState(false)
  const [name, setName] = React.useState('')

  return (
    <div className="text-center h-full flex items-center flex-col">
      <img
        src={
          imagesCache[
            '3d-hands-fun-and-wild-white-skin-hand-giving-high-five-to-tanned-skin-hand-1.webp'
          ] ||
          '/images/3d-hands-fun-and-wild-white-skin-hand-giving-high-five-to-tanned-skin-hand-1.webp'
        }
        alt="3d hand high five"
        className="mx-auto w-[14rem] min-h-[14rem] my-10"
      />
      <h1 className="text-3xl md:text-5xl text-heading font-extrabold">
        {t('onboarding.step2.title')}
      </h1>
      <input
        type="text"
        placeholder={t('onboarding.step2.name-placeholder')}
        autoFocus={false}
        autoComplete="off"
        autoCorrect="off"
        maxLength={30}
        className={`block w-full p-2 rounded-md my-8 ${
          error ? 'border-2 border-red-600' : 'border-2 border-pastel-green/50'
        }`}
        onChange={(e) => {
          setName(e.target.value)
          setError(false)
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            if (name.trim().length < 2) return setError(true)
            onContinue(name.trim())
          }
        }}
      />
      <button
        className="bg-pastel-green text-white font-bold text-xl rounded-xl px-8 py-6 justify-self-end fixed bottom-10 disabled:opacity-50 disabled:bg-slate-600"
        disabled={name.trim().length < 2}
        onClick={() => {
          if (name.trim().length < 2) return setError(true)
          onContinue(name.trim())
        }}>
        {t('onboarding.step2.button')}
      </button>
    </div>
  )
}

export default Step2
