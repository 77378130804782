import { useLocalStorage, useSessionStorage } from '@uidotdev/usehooks'
import i18next from 'i18next'
import React from 'react'

const Step0 = ({ onContinue }: { onContinue: () => void }) => {
  const [lang, setLang] = useLocalStorage('lang', 'en')
  const [imagesCache] = useSessionStorage<any>('images-cache', {})
  const handleChangeLanguage = (language: string) => {
    i18next.changeLanguage(language)
    setLang(language)
    onContinue()
  }

  return (
    <div className="text-center h-full flex items-center flex-col">
      <img
        src={imagesCache['3d-fluency-language.webp'] || '/images/3d-fluency-language.webp'}
        alt="3d language logo"
        className="mx-auto w-[14rem] my-10"
      />
      <ul className="space-y-4 text-left font-medium overflow-y-scroll columns-2 my-auto text-xl cursor-pointer">
        <li onClick={() => handleChangeLanguage('en')}>🇦🇺 English</li>
        <li onClick={() => handleChangeLanguage('fr')}>🇫🇷 Français</li>
        <li onClick={() => handleChangeLanguage('ar')}>🇲🇦 العربية</li>
        <li className="opacity-20">🇪🇸 Español</li>
        <li className="opacity-20">🇮🇳 हिन्दी</li>
        <li className="opacity-20">🇨🇳 中文</li>
        <li className="opacity-20">🇵🇹 Português</li>
        <li className="opacity-20">🇷🇺 Русский</li>
        <li className="opacity-20">🇯🇵 日本語</li>
        <li className="opacity-20">🇩🇪 Deutsch</li>
        <li className="opacity-20">🇰🇷 한국어</li>
        <li className="opacity-20">🇹🇷 Türkçe</li>
      </ul>
    </div>
  )
}

export default Step0
