import { ReactComponent as Avatar1 } from './avatar-001.svg'
import { ReactComponent as Avatar2 } from './avatar-002.svg'
import { ReactComponent as Avatar3 } from './avatar-003.svg'
import { ReactComponent as Avatar4 } from './avatar-004.svg'
import { ReactComponent as Avatar5 } from './avatar-005.svg'
import { ReactComponent as Avatar6 } from './avatar-006.svg'
import { ReactComponent as Avatar7 } from './avatar-007.svg'
import { ReactComponent as Avatar8 } from './avatar-008.svg'
import { ReactComponent as Avatar9 } from './avatar-009.svg'
import { ReactComponent as Avatar10 } from './avatar-010.svg'
import { ReactComponent as Avatar11 } from './avatar-011.svg'
import { ReactComponent as Avatar12 } from './avatar-012.svg'
import { ReactComponent as Avatar13 } from './avatar-013.svg'
import { ReactComponent as Avatar14 } from './avatar-014.svg'
import { ReactComponent as Avatar15 } from './avatar-015.svg'
import { ReactComponent as Avatar16 } from './avatar-016.svg'
import { ReactComponent as Avatar17 } from './avatar-017.svg'
import { ReactComponent as Avatar18 } from './avatar-018.svg'
import { ReactComponent as Avatar19 } from './avatar-019.svg'
import { ReactComponent as Avatar20 } from './avatar-020.svg'
import { ReactComponent as Avatar21 } from './avatar-021.svg'
import { ReactComponent as Avatar22 } from './avatar-022.svg'
import { ReactComponent as Avatar23 } from './avatar-023.svg'
import { ReactComponent as Avatar24 } from './avatar-024.svg'
import { ReactComponent as Avatar25 } from './avatar-025.svg'
import { ReactComponent as Avatar26 } from './avatar-026.svg'
import { ReactComponent as Avatar27 } from './avatar-027.svg'
import { ReactComponent as Avatar28 } from './avatar-028.svg'
import { ReactComponent as Avatar29 } from './avatar-029.svg'
import { ReactComponent as Avatar30 } from './avatar-030.svg'

export const avatars = {
  1: Avatar1,
  2: Avatar2,
  3: Avatar3,
  4: Avatar4,
  5: Avatar5,
  6: Avatar6,
  7: Avatar7,
  8: Avatar8,
  9: Avatar9,
  10: Avatar10,
  11: Avatar11,
  12: Avatar12,
  13: Avatar13,
  14: Avatar14,
  15: Avatar15,
  16: Avatar16,
  17: Avatar17,
  18: Avatar18,
  19: Avatar19,
  20: Avatar20,
  21: Avatar21,
  22: Avatar22,
  23: Avatar23,
  24: Avatar24,
  25: Avatar25,
}
