import { useLocalStorage } from '@uidotdev/usehooks'
import React from 'react'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step0 from './Step0'
import Step4 from './Step4'
import { useNavigate } from 'react-router-dom'

function Onboarding() {
  const navigate = useNavigate()

  const [userData, setUserData] = useLocalStorage('user-data', {
    name: '',
    avatar: '',
    gender: '',
  })
  const [step, setStep] = React.useState(0)

  const steps: { [step: number]: JSX.Element } = {
    0: <Step0 onContinue={() => setStep(1)} />,
    1: <Step1 onContinue={() => setStep(2)} />,
    2: (
      <Step2
        onContinue={(name: string) => {
          setUserData({ ...userData, name: name })
          setStep(3)
        }}
      />
    ),
    3: (
      <Step3
        userData={userData}
        onContinue={(avatar: string) => {
          setUserData({ ...userData, avatar: avatar })
          setStep(4)
        }}
      />
    ),
    4: (
      <Step4
        onContinue={(gender: string) => {
          setUserData({ ...userData, gender: gender })
          navigate('/')
        }}
      />
    ),
  }

  return (
    <div className="w-screen h-screen flex justify-center items-center bg-[#f3f7ff] p-5">
      {steps[step]}
    </div>
  )
}

export default Onboarding
