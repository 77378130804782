import React, { useEffect } from 'react'
import { useLocalStorage } from '@uidotdev/usehooks'
import { Outlet, useNavigate } from 'react-router-dom'
import Navbar from './components/Navigation/Navbar'
import i18next from 'i18next'

const NavLayout = () => {
  const navigate = useNavigate()
  const [userData] = useLocalStorage<{
    name: ''
    avatar: ''
    gender: ''
  }>('user-data')
  const [lang] = useLocalStorage('lang', 'en')

  useEffect(() => {
    i18next.changeLanguage(lang)
  }, [lang])

  React.useEffect(() => {
    if (
      !userData ||
      !userData?.name ||
      !userData?.gender ||
      !userData?.avatar
    ) {
      navigate('/onboarding')
    }
  }, [userData, navigate])

  return (
    <>
      {userData?.avatar && <Navbar userData={userData} />}

      <div className="h-20"></div>
      <main className="w-screen h-full overflow-x-hidden text-xl font-medium font-inter">
        <Outlet />
      </main>
    </>
  )
}

export default NavLayout
