import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { avatars } from '../../assets/avatars'
import { useSessionStorage } from '@uidotdev/usehooks'

const Step4 = ({ onContinue }: { onContinue: (avatar: string) => void }) => {
  const [imagesCache] = useSessionStorage<any>('images-cache', {})
  const { t } = useTranslation()
  const [selectedGender, setSelectedGender] = useState<'male' | 'female'>()

  return (
    <div className="text-center h-full w-full flex items-center flex-col">
      <img
        src={
          imagesCache['morphis-gender-equality-and-harmony.webp'] ||
          '/images/morphis-gender-equality-and-harmony.webp'
        }
        alt="Genders"
        className="mx-auto w-[14rem] min-h-[14rem] my-10"
      />
      <h1 className="text-3xl md:text-5xl text-heading font-extrabold">
        {t('onboarding.step4.title')}
      </h1>
      <p className="font-normal text-paragraph my-4">
        {t('onboarding.step4.description')}
      </p>
      <div className="grid w-full max-w-md grid-cols-2 gap-2 rounded-xl bg-gray-200 p-2 mt-4">
        <div>
          <input
            type="radio"
            name="gender"
            id="1"
            className="peer hidden"
            value="male"
            onChange={(e) => {
              setSelectedGender(e.target.value as 'male' | 'female')
            }}
          />
          <label
            htmlFor="1"
            className="block cursor-pointer select-none rounded-xl p-2 text-center peer-checked:bg-blue-500 peer-checked:font-bold peer-checked:text-white">
            👦 {t('common.boy')}
          </label>
        </div>
        <div>
          <input
            type="radio"
            name="gender"
            id="2"
            value="female"
            className="peer hidden"
            onChange={(e) => {
              setSelectedGender(e.target.value as 'male' | 'female')
            }}
          />
          <label
            htmlFor="2"
            className="block cursor-pointer select-none rounded-xl p-2 text-center peer-checked:bg-pink-500 peer-checked:font-bold peer-checked:text-white">
            👧 {t('common.girl')}
          </label>
        </div>
      </div>
      <button
        className="bg-pastel-green text-white font-bold text-xl rounded-xl px-8 py-6 justify-self-end fixed bottom-10 disabled:opacity-50 disabled:bg-slate-600"
        disabled={!selectedGender}
        onClick={() => {
          if (selectedGender) onContinue(selectedGender)
        }}>
        {t('onboarding.step4.button')}
      </button>
    </div>
  )
}

export default Step4
